<template>

</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Logout',
  computed: mapState({

  }),
  data() {
    return {

    };
  },
  created() {
    this.handleLogout();
  },
  methods: {
    /* ----------Logout----------*/
    handleLogout() {
      this.$store.dispatch('actionsStore/clearStore');
      this.$store.dispatch('actionTypesStore/clearStore');
      this.$store.dispatch('ageCategoriesStore/clearStore');
      this.$store.dispatch('agenciesStore/clearStore');
      this.$store.dispatch('applicantsStore/clearStore');
      this.$store.dispatch('applicationsStore/clearStore');
      this.$store.dispatch('bookmarksStore/clearStore');
      this.$store.dispatch('carePlansStore/clearStore');
      this.$store.dispatch('checkRequestsStore/clearStore');
      this.$store.dispatch('classroomsStore/clearStore');
      this.$store.dispatch('clientsStore/clearStore');
      this.$store.dispatch('contactMethodsStore/clearStore');
      this.$store.dispatch('contactsStore/clearStore');
      this.$store.dispatch('correspondenceStore/clearStore');
      this.$store.dispatch('countiesStore/clearStore');
      this.$store.dispatch('dependantsStore/clearStore');
      this.$store.dispatch('documentsStore/clearStore');
      this.$store.dispatch('documentTypesStore/clearStore');
      this.$store.dispatch('educationStore/clearStore');
      this.$store.dispatch('employmentStore/clearStore');
      this.$store.dispatch('facilitiesStore/clearStore');
      this.$store.dispatch('fundingSourcesStore/clearStore');
      this.$store.dispatch('helpTopicsStore/clearStore');
      this.$store.dispatch('householdsStore/clearStore');
      this.$store.dispatch('incomeStore/clearStore');
      this.$store.dispatch('incomeClassificationsStore/clearStore');
      this.$store.dispatch('navigationStore/clearStore');
      this.$store.dispatch('notesStore/clearStore');
      this.$store.dispatch('noteTopicsStore/clearStore');
      this.$store.dispatch('paymentsStore/clearStore');
      this.$store.dispatch('programsStore/clearStore');
      this.$store.dispatch('progressStore/clearStore');
      this.$store.dispatch('providersStore/clearStore');
      this.$store.dispatch('ratesStore/clearStore');
      this.$store.dispatch('ratingsStore/clearStore');
      this.$store.dispatch('reportsStore/clearStore');
      this.$store.dispatch('schoolZonesStore/clearStore');
      this.$store.dispatch('tasksStore/clearStore');
      this.$store.dispatch('taskTypesStore/clearStore');
      this.$store.dispatch('templatesStore/clearStore');
      this.$store.dispatch('timeOptionsStore/clearStore');
      this.$store.dispatch('tuitionStore/clearStore');
      this.$store.dispatch('usersStore/clearStore');
      this.$store.dispatch('valueListsStore/clearStore');
      this.$store.dispatch('waitingListStore/clearStore');
      this.$store.dispatch('yearsStore/clearStore');

      window.localStorage.removeItem('authUser');

      window.localStorage.removeItem('appMode');

      /* Send user to index */
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped>

</style>
